// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-index-js": () => import("/opt/build/repo/src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-index-js": () => import("/opt/build/repo/src/pages/lessons/index.js" /* webpackChunkName: "component---src-pages-lessons-index-js" */),
  "component---src-pages-lesson-plans-announcements-00-intro-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/00-intro.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-00-intro-md" */),
  "component---src-pages-activities-essays-md": () => import("/opt/build/repo/src/pages/activities/essays.md" /* webpackChunkName: "component---src-pages-activities-essays-md" */),
  "component---src-pages-lessons-09-css-layout-md": () => import("/opt/build/repo/src/pages/lessons/09-css-layout.md" /* webpackChunkName: "component---src-pages-lessons-09-css-layout-md" */),
  "component---src-pages-resources-md": () => import("/opt/build/repo/src/pages/resources.md" /* webpackChunkName: "component---src-pages-resources-md" */),
  "component---src-pages-lesson-plans-index-md": () => import("/opt/build/repo/src/pages/lesson-plans/index.md" /* webpackChunkName: "component---src-pages-lesson-plans-index-md" */),
  "component---src-pages-lessons-00-introduction-md": () => import("/opt/build/repo/src/pages/lessons/00-introduction.md" /* webpackChunkName: "component---src-pages-lessons-00-introduction-md" */),
  "component---src-pages-lessons-04-organizers-md": () => import("/opt/build/repo/src/pages/lessons/04-organizers.md" /* webpackChunkName: "component---src-pages-lessons-04-organizers-md" */),
  "component---src-pages-lessons-05-forms-tables-md": () => import("/opt/build/repo/src/pages/lessons/05-forms-tables.md" /* webpackChunkName: "component---src-pages-lessons-05-forms-tables-md" */),
  "component---src-pages-lessons-06-intro-to-css-md": () => import("/opt/build/repo/src/pages/lessons/06-intro-to-css.md" /* webpackChunkName: "component---src-pages-lessons-06-intro-to-css-md" */),
  "component---src-pages-lessons-07-styling-content-md": () => import("/opt/build/repo/src/pages/lessons/07-styling-content.md" /* webpackChunkName: "component---src-pages-lessons-07-styling-content-md" */),
  "component---src-pages-lessons-08-box-model-md": () => import("/opt/build/repo/src/pages/lessons/08-box-model.md" /* webpackChunkName: "component---src-pages-lessons-08-box-model-md" */),
  "component---src-pages-activities-course-project-md": () => import("/opt/build/repo/src/pages/activities/course-project.md" /* webpackChunkName: "component---src-pages-activities-course-project-md" */),
  "component---src-pages-lessons-01-intro-to-html-md": () => import("/opt/build/repo/src/pages/lessons/01-Intro-to-html.md" /* webpackChunkName: "component---src-pages-lessons-01-intro-to-html-md" */),
  "component---src-pages-lessons-03-links-images-md": () => import("/opt/build/repo/src/pages/lessons/03-links-images.md" /* webpackChunkName: "component---src-pages-lessons-03-links-images-md" */),
  "component---src-pages-lessons-02-content-markup-md": () => import("/opt/build/repo/src/pages/lessons/02-content-markup.md" /* webpackChunkName: "component---src-pages-lessons-02-content-markup-md" */),
  "component---src-pages-lessons-10-building-layouts-md": () => import("/opt/build/repo/src/pages/lessons/10-building-layouts.md" /* webpackChunkName: "component---src-pages-lessons-10-building-layouts-md" */)
}

